import React from "react";
import "./App.css";
import "./Tutorial.css";
import "./EntryText.css";
import { useState, useRef, useEffect } from "react";
import { ListItem } from "./Components/ListItem";
import arrow from "./photos/arrow.svg";

import iconCaret from "./photos/caret.svg";

import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

import { wordStyles } from "./wordStyles";

export function Tutorial(props) {
  const { t, i18n } = useTranslation();

  const [currentIndex, setCurrentIndex] = useState(0);

  const next = () => {
    setCurrentIndex(currentIndex + 1);
    if (currentIndex === 4) {
      if (props.setFirstTime !== undefined) props.setFirstTime("false");
      if (props.setShowTutorial !== undefined) props.setShowTutorial(false);
    }
  };

  const prev = () => {
    setCurrentIndex(currentIndex - 1);
  };

  function prevButtonRender() {
    if (currentIndex > 0) {
      return (
        <div
          className="button-primary button-text-l text-color-black shadow-black"
          onClick={prev}
          style={{ maxWidth: "72px", minWidth: "72px" }}
        >
          <img style={{ marginTop: "16px" }} src={arrow} alt="" />
        </div>
      );
    } else {
      return (
        <div
          className="button-primary button-text-l text-color-black shadow-black box-button-center"
          onClick={prev}
          style={{ maxWidth: "72px", minWidth: "72px", visibility: "hidden" }}
        >
          <img style={{ marginTop: "16px" }} src={arrow} alt="" />
        </div>
      );
    }
  }

  function nextButtonRender() {
    return (
      <div
        className="button-primary button-text-l text-color-black shadow-black box-button-center"
        onClick={next}
        style={{ width: "65%" }}
      >
        {t("next")}
      </div>
    );
  }

  function step0() {
    return (
      <div style={{ overflow: "hidden", overflowY: "scroll" }}>
        <div className="progress-container bottom-margin-l">
          <div className="progress-tab-inactive"></div>
          <div className="progress-tab-inactive"></div>
          <div className="progress-tab-inactive"></div>
          <div className="progress-tab-inactive"></div>
        </div>
        <div className="text-h1 text-color-pink bottom-margin-l">
          {t("step-0-header")}
        </div>
        <p className="tutorial-text">{t("step-0-text1")}</p>
      </div>
    );
  }

  function step1() {
    return (
      <div style={{ overflow: "hidden", overflowY: "scroll" }}>
        <div className="progress-container bottom-margin-l">
          <div className="progress-tab-active"></div>
          <div className="progress-tab-inactive"></div>
          <div className="progress-tab-inactive"></div>
          <div className="progress-tab-inactive"></div>
        </div>
        <div className="text-h1 text-color-pink bottom-margin-l">
          {t("step-1")}
        </div>
        <div className="text-h2 bottom-margin-m">{t("step-1-header1")}</div>
        <p className="tutorial-text bottom-margin-l">{t("step-1-text1")}</p>

        <div className="text-h2 bottom-margin-m">{t("step-1-header2")}</div>
        <p className="tutorial-text">{t("step-1-text2")}</p>
      </div>
    );
  }

  function step2() {
    return (
      <div style={{ overflow: "hidden", overflowY: "scroll" }}>
        <div className="progress-container bottom-margin-l">
          <div className="progress-tab-active"></div>
          <div className="progress-tab-active"></div>
          <div className="progress-tab-inactive"></div>
          <div className="progress-tab-inactive"></div>
        </div>
        <div className="text-h1 text-color-pink bottom-margin-l">
          {t("step-2")}
        </div>
        <div className="text-h2 bottom-margin-m">{t("step-2-header1")}</div>
        <p className="tutorial-text bottom-margin-l">{t("step-2-text1")}</p>
      </div>
    );
  }

  function step3() {
    return (
      <div style={{ overflow: "hidden", overflowY: "scroll" }}>
        <div className="progress-container bottom-margin-l">
          <div className="progress-tab-active"></div>
          <div className="progress-tab-active"></div>
          <div className="progress-tab-active"></div>
          <div className="progress-tab-inactive"></div>
        </div>
        <div className="text-h1 text-color-pink bottom-margin-l">
          {t("step-3")}
        </div>
        <div className="text-h2 bottom-margin-m">{t("step-3-header1")}</div>
        <p className="tutorial-text">{t("step-3-text1")}</p>
      </div>
    );
  }

  function step4() {
    return (
      <div style={{ overflow: "hidden", overflowY: "scroll" }}>
        <div className="progress-container bottom-margin-l">
          <div className="progress-tab-active"></div>
          <div className="progress-tab-active"></div>
          <div className="progress-tab-active"></div>
          <div className="progress-tab-active"></div>
        </div>
        <div className="text-h1 text-color-pink bottom-margin-l">
          {t("step-4")}
        </div>
        <div className="text-h2 bottom-margin-m">{t("step-4-header1")}</div>
        <p className="tutorial-text bottom-margin-l">{t("step-4-text1")}</p>
      </div>
    );
  }

  function stepRenderer() {
    if (currentIndex === 0) return step0();
    else if (currentIndex === 1) return step1();
    else if (currentIndex === 2) return step2();
    else if (currentIndex === 3) return step3();
    else if (currentIndex === 4) return step4();
  }

  return (
    <div className="App-header">
      {stepRenderer()}
      <div className="container-row">
        {prevButtonRender()}
        {nextButtonRender()}
      </div>
    </div>
  );
}
