import React, { useState, useEffect } from "react";
import "./App.css";
import { NewRoom } from "./NewRoom";
import { Tutorial } from "./Tutorial";
import { Background } from "./Background";

import fyllaVertical from "./photos/fyllaVertical.svg";
import chevron from "./photos/BiChevronsRight.svg";
import chevronWhite from "./photos/BiChevronsRightWhite.svg";
import globe from "./photos/globe.svg";
import linkArrow from "./photos/linkArrow.svg";

import socketIO from "socket.io-client";

import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

import { InfoMessage } from "./Components/InfoMessage";

const socket = socketIO.connect("https://www.fyl.la:4000", {
  transports: ["websocket", "polling", "flashsocket"],
});
//const socket = socketIO.connect('http://192.168.86.239:4000', { transports: ['websocket', 'polling', 'flashsocket'] });

const fetchString = "https://www.fyl.la:4000/api/";
//const fetchString = 'http://192.168.86.239:4000/api/';

export function StartScreen() {
  const [roomName, setRoomName] = useState("");
  const [openNewRoom, setOpenNewRoom] = useState(false);
  const [openJoin, setopenJoin] = useState(false);
  const [openCreate, setopenCreate] = useState(false);
  const [openMenu, setopenMenu] = useState(false);
  const [roomInput, setRoomInput] = useState("");

  const [shouldShow, setShouldShow] = useState(false);
  const [color, setColor] = useState("");
  const [text, setText] = useState("");
  const [add, setAdd] = useState(false);
  function showInfoMessage(type, message, isAdd) {
    if (isAdd) setAdd(true);
    else setAdd(false);

    if (type === "warning") {
      setColor("#FF4D00");
    } else {
      setColor("#F42272");
    }
    setText(message);
    setShouldShow(true);

    if (isAdd) {
      setTimeout(() => {
        setShouldShow(false);
      }, 2500);
    } else {
      setTimeout(() => {
        setShouldShow(false);
      }, 5000);
    }
  }

  useEffect(() => {
    const targetElement = document.querySelector(".background");

    function updateThemeColor() {
      if (!targetElement) return;
      const computedStyle = getComputedStyle(targetElement);
      const bgColor = computedStyle.backgroundColor;
      let metaThemeColor = document.querySelector('meta[name="theme-color"]');
      if (metaThemeColor) {
        metaThemeColor.setAttribute("content", rgbToHex(bgColor));
      } else {
        metaThemeColor = document.createElement("meta");
        metaThemeColor.name = "theme-color";
        metaThemeColor.content = rgbToHex(bgColor);
        document.head.appendChild(metaThemeColor);
      }
    }

    function rgbToHex(rgb) {
      const result = rgb.match(/\d+/g);
      if (!result) return "#000000";
      return (
        "#" +
        result.map((x) => ("0" + parseInt(x).toString(16)).slice(-2)).join("")
      );
    }

    const intervalId = setInterval(updateThemeColor, 50);
    return () => clearInterval(intervalId);
  }, []);

  const [firstTime, setFirstTime] = useState(() => {
    const saved = localStorage.getItem("firstTime");
    return saved || "true";
  });

  const [language, setLanguage] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("language");
    return saved || "norsk";
  });

  const { t, i18n } = useTranslation();

  const [containerHeight, setContainerHeight] = useState(0);

  const [isMenuAnimating, setIsMenuAnimating] = useState(false);

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isRightSwipe) setopenMenu(true);
    if (isLeftSwipe) {
      setIsMenuAnimating(true);
      setTimeout(() => {
        setIsMenuAnimating(false);
        setopenMenu(false);
      }, 500); // Adjust the timeout based on your animation duration
    }
  };

  useEffect(() => {
    const updateContainerHeight = () => {
      const vh = window.innerHeight * 0.01;
      setContainerHeight(window.innerHeight);
      document.documentElement.style.setProperty("--vh", vh);
    };

    updateContainerHeight();
    window.addEventListener("resize", updateContainerHeight);

    localStorage.setItem("language", language);
    i18n.changeLanguage(language);

    localStorage.setItem("firstTime", firstTime);

    return () => {
      window.removeEventListener("resize", updateContainerHeight);
    };
  }, [language, firstTime]);

  const onInputChange = (e) => {
    const input = e.currentTarget.value;
    if (/^[^!-\/:-@\[-`{-~]+$/.test(input) || input === "") {
      setRoomInput(input);
    }
  };

  const onKeyDownJoin = (event) => {
    if (event.key === "Enter") {
      joinRoom();
    }
  };

  const onKeyDownCreate = (event) => {
    if (event.key === "Enter") {
      createRoom();
    }
  };
  const joinRoom = () => {
    if (roomInput !== "") {
      canJoin();
      setRoomName(roomInput);
    }
    document.getElementById("page").scrollIntoView();
  };

  const canJoin = async () => {
    var doesKeyExist = await isPresent(roomInput.toLowerCase().trim());
    if (doesKeyExist) {
      setOpenNewRoom(!openNewRoom);
      setopenJoin(false);
    } else showInfoMessage("warning", t("no-exist"), false);
  };

  const isPresent = async (input) => {
    return fetch(fetchString + input, {
      headers: {
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        return JSON.stringify(data) !== "{}";
      });
  };

  const idIsFree = async (input) => {
    var present = await isPresent(input);
    return !present;
  };

  const createRoom = async () => {
    if (roomInput !== "") {
      const free = await idIsFree(roomInput.toLowerCase().trim());
      if (free) {
        socket.emit("new room", roomInput.toLowerCase().trim());
        setRoomName(roomInput.toLowerCase().trim());
        setOpenNewRoom(true);
        setopenCreate(false);
      } else showInfoMessage("warning", t("taken"), false);
    }
    document.getElementById("page").scrollIntoView();
  };

  const openJoinFunc = () => {
    setopenJoin(!openJoin);
  };

  const openCreateFunc = () => {
    setopenCreate(!openCreate);
  };

  const changeLanguage = () => {
    if (language === "norsk") {
      setLanguage("english");
      i18n.changeLanguage("english");
    } else {
      setLanguage("norsk");
      i18n.changeLanguage("norsk");
    }
  };

  const closeMenu = () => {
    setIsMenuAnimating(true);
    setTimeout(() => {
      setIsMenuAnimating(false);
      setopenMenu(false);
    }, 500); // Adjust the timeout based on your animation duration
  };

  function startPageRender() {
    if (!openJoin && !openCreate) {
      return (
        <div className="App-header">
          <div
            className="button-primary white-fill button-text-l text-color-black shadow-black box-button-center"
            onClick={() => openCreateFunc()}
          >
            {t("new-room")}
          </div>
          {/* <span className='announcement'>
                        <p className='text-stroke' style={{ fontSize: '35px', textAlign: 'center' }}>NY OPPDATERING 😍</p>
                        <p className='text-stroke' style={{ fontSize: '25px', fontWeight: 600, textAlign: 'center' }}>Du kan nå legge til ting etter at runden har startet!</p>
                    </span> */}
          <div
            className="swipe-area"
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
          >
            <p
              className="button-tertiary-s button-text-s text-color-black"
              style={{ maxHeight: "25px", marginBottom: "7px" }}
              onClick={() => setopenMenu(true)}
            >
              {t("menu")}
            </p>
            <img
              className="stuttering-arrow-right"
              src={chevron}
              onClick={() => setopenMenu(true)}
              alt=""
            />
          </div>
          <div
            className="button-primary white-fill button-text-l text-color-black shadow-black box-button-center"
            onClick={() => openJoinFunc()}
          >
            {t("join-room")}
          </div>
        </div>
      );
    } else return <></>;
  }

  function createRoomRender() {
    if (openCreate) {
      return (
        <div className="App-header">
          <div className="container-row-start lift-topbar">
            <div
              className="button-tertiary-m button-text-m text-color-black"
              onClick={() => openCreateFunc()}
              style={{ maxHeight: "32.5px" }}
            >
              {t("back")}
            </div>
          </div>
          <div className="container">
            <input
              className="button-primary shadow-black text-h3 bottom-margin-l"
              autoComplete="off"
              type="text"
              maxLength="18"
              value={roomInput}
              onChange={onInputChange}
              onKeyDown={onKeyDownCreate}
              placeholder={t("room-id")}
            ></input>
            <div
              className="button-primary black-fill button-text-l text-color-pink shadow-pink box-button-center"
              onClick={createRoom}
            >
              {t("create")}
            </div>
          </div>
        </div>
      );
    } else return <></>;
  }

  function joinRoomRender() {
    if (openJoin) {
      return (
        <div className="App-header">
          <div className="container-row-start lift-topbar">
            <div
              className="button-tertiary-m button-text-m text-color-black"
              onClick={() => openJoinFunc()}
              style={{ maxHeight: "32.5px" }}
            >
              {t("back")}
            </div>
          </div>
          <div className="container">
            <input
              className="button-primary shadow-black text-h3 bottom-margin-l"
              autoComplete="off"
              type="text"
              maxLength="18"
              value={roomInput}
              onChange={onInputChange}
              onKeyDown={onKeyDownJoin}
              placeholder={t("room-id")}
            ></input>
            <div
              className="button-primary black-fill button-text-l text-color-pink shadow-pink box-button-center"
              onClick={joinRoom}
            >
              JOIN
            </div>
          </div>
        </div>
      );
    } else return <></>;
  }

  function menuRender() {
    if (openMenu) {
      return (
        <div
          className={`menu-container ${isMenuAnimating ? "menu-close" : ""}`}
          style={{ height: containerHeight - 48 }}
        >
          <div className="fylla-vertical-container">
            <img className="fylla-vertical" src={fyllaVertical} alt="" />
          </div>
          <div className="menu-item">
            <div className="container-row-end" onClick={changeLanguage}>
              <div
                className="button-tertiary-s button-text-s text-color-white"
                style={{
                  maxWidth: "203px",
                  maxHeight: "28px",
                  marginRight: "10px",
                  textTransform: "uppercase",
                }}
              >
                {language}
              </div>
              <img
                style={{ marginRight: "70px", paddingTop: "3px" }}
                src={globe}
                alt=""
              />
            </div>
            <a
              className="container-row-start"
              href="https://www.instagram.com/fyl.la_/"
              rel="noopener noreferrer"
            >
              <h1
                style={{
                  marginTop: "20px",
                  maxWidth: "115px",
                  maxHeight: "57px",
                }}
              >
                INSTA
              </h1>
              <img
                style={{ marginLeft: "10px", paddingTop: "0px" }}
                src={linkArrow}
                alt=""
              />
            </a>
            <h1 style={{ marginTop: "-10px" }}>Vipps en øl</h1>
            <h2 style={{ marginTop: "-50px" }}>46786400</h2>
          </div>
          <div
            className="swipe-area"
            style={{ justifyContent: "flex-end" }}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
          >
            <img
              className="stuttering-arrow-left"
              style={{ paddingRight: "48px" }}
              src={chevronWhite}
              onClick={closeMenu}
              alt=""
            />
          </div>
          {language === "norsk" ? (
            <div className="menu-item" style={{ justifyContent: "end" }}>
              <a
                className="button-tertiary-m button-text-m text-color-white"
                href="https://fyl.la/privacy-policy"
                rel="noopener noreferrer"
                style={{ maxWidth: "166px", maxHeight: "32.5px" }}
              >
                {t("privacy-policy")}
              </a>
              <a
                className="button-tertiary-m button-text-m text-color-white"
                href="https://fyl.la/terms-of-service"
                rel="noopener noreferrer"
                style={{ maxWidth: "95px", maxHeight: "32.5px" }}
              >
                {t("terms-of-service")}
              </a>
            </div>
          ) : (
            <div className="menu-item" style={{ justifyContent: "end" }}>
              <a
                className="button-tertiary-m button-text-m text-color-white"
                href="https://fyl.la/privacy-policy"
                rel="noopener noreferrer"
                style={{ maxWidth: "203px", maxHeight: "32.5px" }}
              >
                {t("privacy-policy")}
              </a>
              <a
                className="button-tertiary-m button-text-m text-color-white"
                href="https://fyl.la/terms-of-service"
                rel="noopener noreferrer"
                style={{ maxWidth: "223px", maxHeight: "32.5px" }}
              >
                {t("terms-of-service")}
              </a>
            </div>
          )}
        </div>
      );
    } else return <></>;
  }

  return (
    <div>
      <InfoMessage
        shouldShow={shouldShow}
        color={color}
        text={text}
        add={add}
        setShouldShow={setShouldShow}
      />
      <div id="page" style={{ touchAction: "none" }}>
        <div>
          {openNewRoom === false ? (
            <div className="App" style={{ height: containerHeight - 48 }}>
              <header className="App-header">
                {menuRender()}
                {startPageRender()}
                {createRoomRender()}
                {joinRoomRender()}
              </header>
            </div>
          ) : (
            <div className="App" style={{ height: containerHeight - 48 }}>
              <header className="App-header">
                {firstTime === "true" ? (
                  <Tutorial setFirstTime={setFirstTime}></Tutorial>
                ) : (
                  <NewRoom
                    socket={socket}
                    roomName={roomName.toLowerCase().trim()}
                    openNewRoom={openNewRoom}
                    setOpenNewRoom={setOpenNewRoom}
                    containerHeight={containerHeight}
                    showInfoMessage={showInfoMessage}
                  ></NewRoom>
                )}
              </header>
            </div>
          )}
        </div>

        <Background
          openNewRoom={openNewRoom}
          containerHeight={containerHeight}
        />
      </div>
    </div>
  );
}
