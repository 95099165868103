import React from "react";
import "./App.css";
import "./Tutorial.css";
import "./EntryText.css";
import fylla1 from "./photos/fylla1.svg";
import fylla2 from "./photos/fylla2.svg";
import fylla3 from "./photos/fylla3.svg";
import fylla4 from "./photos/fylla4.svg";
import fylla5 from "./photos/fylla5.svg";

export function Background(props) {
  const today = new Date();
  const isLast7DaysOfJune = today.getMonth() === 5 && today.getDate() > 30 - 7;
  const isMay17th = today.getMonth() === 4 && today.getDate() === 17;

  function renderMainBackground() {
    return (
      <div className="background">
        <div className="background-child">
          <img className="fylla1" src={fylla1} alt="" />
          <img className="fylla2" src={fylla2} alt="" />
          <img className="fylla3" src={fylla3} alt="" />
          <img className="fylla4" src={fylla4} alt="" />
          <img className="fylla5" src={fylla5} alt="" />
          <img className="fylla1" src={fylla1} alt="" />
          <img className="fylla2" src={fylla2} alt="" />
          <img className="fylla3" src={fylla3} alt="" />
          <img className="fylla4" src={fylla4} alt="" />
        </div>
      </div>
    );
  }

  function renderPride() {
    return (
      <div className="happening-pride">
        <div
          className="pride-1"
          style={{
            backgroundColor: "#E40303",
            height: props.containerHeight / 6,
            width: "3000px",
          }}
        ></div>
        <div
          className="pride-2"
          style={{
            backgroundColor: "#FF8C00",
            height: props.containerHeight / 6,
            width: "3000px",
          }}
        ></div>
        <div
          className="pride-3"
          style={{
            backgroundColor: "#FFED00",
            height: props.containerHeight / 6,
            width: "3000px",
          }}
        ></div>
        <div
          className="pride-4"
          style={{
            backgroundColor: "#008026",
            height: props.containerHeight / 6,
            width: "3000px",
          }}
        ></div>
        <div
          className="pride-5"
          style={{
            backgroundColor: "#24408E",
            height: props.containerHeight / 6,
            width: "3000px",
          }}
        ></div>
        <div
          className="pride-6"
          style={{
            backgroundColor: "#732982",
            height: props.containerHeight / 6,
            width: "3000px",
          }}
        ></div>
      </div>
    );
  }

  function renderContent() {
    if (props.openNewRoom === false) {
      if (isLast7DaysOfJune) {
        return renderPride();
      }
      if (isMay17th) {
        return <div className="happening-17th-may"></div>;
      }
      return renderMainBackground();
    }
    return <div className="background" />;
  }

  return renderContent();
}
