import React from "react";
import "./InfoMessage.css";
import "../App.css";
import iconCross from "../photos/cross.svg";

export function InfoMessage(props) {
  if (!props.shouldShow) {
    return <></>;
  } else {
    if (!props.add) {
      return (
        <div style={{ position: "absolute", width: "100%", zIndex: 5 }}>
          <div
            className={`info-message-container info-message-slide-in`}
            style={{ backgroundColor: props.color }}
          >
            <div className="text-h4">{props.text}</div>
            <img
              src={iconCross}
              alt="Close Icon"
              onClick={() => props.setShouldShow(false)}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ position: "absolute", width: "100%" }}>
          <div
            className={`add-container info-message-slide-in`}
            style={{ backgroundColor: props.color }}
          >
            <div className="text-h4">{props.text}</div>
          </div>
        </div>
      );
    }
  }
}
